<template>
	<div>
		<!--企业资料-->
		<div class="company-info-box">
			<div class="file-title">
				<h3>企业资料</h3>
				<a href="javascript:;" class="look-example" @click="companyDialogVisible = true">查看示例</a>
			</div>
			<!-- 
						测试： http://117.122.218.200:11001
						生产： http://117.122.218.218:11001
					 -->
			<div class="company-info">
				<el-upload action="http://117.122.218.200:11001/cpiaoju-ticket/front-company-register/uploadToOss"
				           accept=".jpg,.jpeg,.png"
									 class="upload-demo"
									 list-type="picture-card"
								   :show-file-list="false"
				           name="multipartFile"
								   :on-success="(response, file, fileList)=> companyAccess.businessLicense = response.data">
					<img class="upload-img" :src="companyAccess.businessLicense" alt="" v-if= "companyAccess.businessLicense">
					<i class="el-icon-plus" v-else></i>
					<div slot="tip" class="el-upload__tip">
						统一社会信用代码证 <br/>（即三证合一的营业执照）
					</div>
				</el-upload>
				<el-upload action="http://117.122.218.200:11001/cpiaoju-ticket/front-company-register/uploadToOss"
									 accept=".jpg,.jpeg,.png"
									 class="upload-demo"
									 list-type="picture-card"
									 name="multipartFile"
									 :show-file-list="false"
									 :on-success="(response, file, fileList)=> companyAccess.legalIdCard = response.data">
					<img class="upload-img" :src="companyAccess.legalIdCard" alt="" v-if="companyAccess.legalIdCard">
					<i class="el-icon-plus" v-else></i>
					<div slot="tip" class="el-upload__tip">
						法人代表身份证复印件<br/>（正反面）
					</div>
				</el-upload>
			</div>
		</div>
		<!--银行账户资料-->
		<div class="bank-info-box">
			<div class="file-title">
				<h3>银行账户资料</h3>
				<a href="javascript:;" class="look-example" @click="bankDialogVisible = true">查看示例</a>
			</div>
			<div class="upload-explain">
				<p>
					<img src="~@/assets/img/ticket/explain.png" alt="">上传说明：加盖公章后再上传
				</p>
				<span>
					基本账户：开户许可证复印件<br/>
					一般账户：银行流水回单，网银截屏或者其他显示账户信息的复印件<br/>
          <!--个人账户：银行卡号正反面复印件或者存折账号页复印件，复印件上请写上开户姓名及开户网点-->
				</span>
			</div>
			<ul class="bank-info">
				<li v-for="item in companyAccess.bankAccountList" :key="item.id" v-if="item.bankExtId !== null">
					<p>账号：{{item.bankNumber}}</p>
					<el-upload action="http://117.122.218.200:11001/cpiaoju-ticket/front-company-register/uploadToOss"
										 accept=".jpg,.jpeg,.png"
										 class="upload-demo"
										 list-type="picture-card"
										 name="multipartFile"
										 :show-file-list="false"
										 :on-success="(response, file, fileList)=> item.bankAccountData = response.data">
						<img class="upload-img" :src="item.bankAccountData" alt="" v-if="item.bankAccountData">
						<i class="el-icon-plus" v-else></i>
					</el-upload>
				</li>
			</ul>
		</div>
		<!--操作员资料-->
		<div class="operator-info-box">
			<div class="file-title">
				<h3>操作员资料</h3>
				<a href="http://117.122.218.200:11001/cpiaoju-ticket/common/download?url=https://oss.cpiaoju.com/lxgw/gpxt/protocol/授权委托书.pdf&fileName=下载授权委托书模板.pdf" class="download-template">下载授权委托书模板</a>
				<a href="javascript:;"
				   class="look-example"
				   @click="operatorDialogVisible = true">
					查看示例
				</a>
			</div>
			<el-form>
				<el-form-item v-for="item in companyAccess.frontUserList" :label="item.userName" :key="item.id" label-width="100px" v-if="item.operationExtId !== null">
					<el-upload action="http://117.122.218.200:11001/cpiaoju-ticket/front-company-register/uploadToOss"
					           accept=".jpg,.jpeg,.png"
										 class="upload-demo"
										 list-type="picture-card"
										 name="multipartFile"
										 :show-file-list="false"
										:on-success="(response, file, fileList)=> item.idCardFrontBack = response.data">
						<img class="upload-img" :src="item.idCardFrontBack" alt="" v-if="item.idCardFrontBack">
						<i class="el-icon-plus" v-else></i>
						<div slot="tip" class="el-upload__tip">
							身份证复印件（正反面）
						</div>
					</el-upload>
					<!--生产：http://117.122.218.200:11001/cpiaoju-ticket/front-company-register/uploadToOss-->
					<el-upload action="http://117.122.218.200:11001/cpiaoju-ticket/front-company-register/uploadToOss"
										 accept=".pdf"
										 class="upload-demo"
										 list-type="picture-card"
										 name="multipartFile"
										 :show-file-list="false"
										 :on-success="(response, file, fileList)=> item.authorizationEntrustBook = response.data">
						<img class="upload-img" src="https://cpiaoju.oss-cn-beijing.aliyuncs.com/lxgw/gpxt/upload_image/pdf.png" alt="" v-if="item.authorizationEntrustBook">
						<i class="el-icon-plus" v-else></i>
						<div slot="tip" class="el-upload__tip">
							授权委托书
						</div>
					</el-upload>
				</el-form-item>
			</el-form>
		</div>
		<div class="btn">
			<el-button @click="goAccessOne">上一步</el-button>
			<el-button type="primary" @click="saveAccessTwo">保存并继续</el-button>
		</div>
		<!--统一社会信用代码证-->
		<el-dialog :visible.sync="companyDialogVisible"
								width="80%">
			<ul class="dialog company-dialog">
				<li>
					<img src="~@/assets/img/ticket/example-unified.png" alt="">
					<p>统一社会信用代码证（三证合一的营业执照）</p>
					<span>（加盖公章）</span>
				</li>
				<li>
					<img src="~@/assets/img/ticket/example-identity.png" alt="">
					<p>法人代表身份证复印件（正反面）</p>
					<span>（加盖公章）</span>
				</li>
			</ul>
		</el-dialog>
		<!--银行账户资料-->
		<el-dialog :visible.sync="bankDialogVisible"
		           width="80%">
			<ul class="dialog bank-dialog">
				<li>
					<img src="~@/assets/img/ticket/bank-01.png" alt="">
					<p>开户许可证复印件（基本账户）</p>
					<span>（加盖公章）</span>
				</li>
				<li>
					<img src="~@/assets/img/ticket/bank-02.png" alt="">
					<p>银行流水回单（一般账户）</p>
					<span>（加盖公章）</span>
				</li>
				<li>
					<img src="~@/assets/img/ticket/bank-03.png" alt="">
					<p>银行卡号正反面复印件（个人账户）</p>
					<span>（加盖公章）</span>
				</li>
			</ul>
		</el-dialog>
		<!--操作员资料-->
		<el-dialog :visible.sync="operatorDialogVisible"
		           width="80%">
			<ul class="dialog company-dialog">
				<li>
					<img src="~@/assets/img/ticket/example-identity.png" alt="">
					<p>身份证复印件（正反面）</p>
					<span>（加盖公章）</span>
				</li>
				<li>
					<img src="~@/assets/img/ticket/operaotr-01.png" alt="">
					<p>授权委托书</p>
					<span>（加盖公章）</span>
				</li>
			</ul>
		</el-dialog>
	</div>
</template>

<script>
	import axios from 'axios'
	import DelayButton from "@/views/Ticket/childrenCpn/DelayButton";
  export default {
    name: "AccessTwo",
	  components: {
      DelayButton
	  },
	  data(){
      return {
        flag: false, // 判断图片必传开关
        companyAccess:{}, // 企业准入数据
        companyDialogVisible: false, //控制统一社会信用代码证显示与隐藏
        bankDialogVisible: false, // 控制银行账户资料显示与隐藏
        operatorDialogVisible: false, // 控制操作员资料显示与隐藏
	      pdfAddress: 'https://cpiaoju.oss-cn-beijing.aliyuncs.com/test/abc.pdf',
	      pdfFileName: 'a.pdf'
      }
	  },
	  mounted(){
      // 获取企业准入数据
      this.getCompanyAccess()
	  },
	  methods: {
      // 获取企业准入数据
      async getCompanyAccess(){
        const {data: res} = await this.$auth.get('cpiaoju-ticket/front-company-register/getCompanyInfo');
        if (res.code !== 200) return this.$message.error(res.msg)
        this.companyAccess = res.data
      },
		  // 上一步
      goAccessOne(){
        this.$emit('saveAccessTwo', 1)
      },
      // 提交 第二步
		  saveAccessTwo(){
        let params = {
          operationPage: 2,
	        id: this.companyAccess.companyExtId,
          businessLicense: this.companyAccess.businessLicense,
          legalIdCard: this.companyAccess.legalIdCard,
          bankAccountListStr: JSON.stringify(this.companyAccess.bankAccountList), // 账户信息（可能多条）
          frontUserListStr: JSON.stringify(this.companyAccess.frontUserList), //操作员信息（可能多条）
        }
        if(this.companyAccess.businessLicense == '' || this.companyAccess.businessLicense == null){
          return this.$message.error('统一社会信用代码证必传')
        }else if(this.companyAccess.legalIdCard == '' || this.companyAccess.legalIdCard == null){
          return this.$message.error('法人代表身份证复印件必传')
        }
        // 判断银行账户资料图片必传
        this.companyAccess.bankAccountList.forEach(item => {
            try {
              if (!item.bankAccountData) {
                if (item.bankExtId !== null){
                  this.flag = false;
                  this.$message.error('银行账户资料必传')
                  return false
                }
              }else {
                // 判断操作员资料图片必传
                this.companyAccess.frontUserList.forEach( item => {
                  if(!item.idCardFrontBack && item.operationExtId){
                    this.flag = false
                    return this.$message.error('操作员身份证复印件必传')
                  }else if (!item.authorizationEntrustBook && item.operationExtId){
                    this.flag = false
                    return this.$message.error('授权委托书必传')
                  }
                  else {
                    this.flag = true
                  }
                })
              }
            }catch (e) {
              console.log(e)
            }
          
        });
	      this.$nextTick(async () =>{
          if (this.flag){
            const {data: res} = await this.$auth.post('/cpiaoju-ticket/front-company-register/addOrUpdCompanyAccess',this.$qs.stringify(params))
						if(res.code !== 200) return this.$message.error(res.msg)
						this.$emit('saveAccessTwo', 3)
          }
	      })
      },
			// 下载授权委托书模板
     /* handleDownloadPDF(){
        const res = axios.get(`http://172.16.88.80:11001/cpiaoju-ticket/common/download?url=
https://cpiaoju.oss-cn-beijing.aliyuncs.com/test/abc.pdf&fileName=a.pdf`);
        console.log(res)
      }*/
	  }
  }
</script>

<style lang="less" scoped>
	.verify-code{
		width: 220px;
	}
	.company-info{
		margin-top: 20px;
		display: flex;
		justify-content: space-evenly;
		.upload-demo{
			margin-right: 20px;
		}
	}
	
	/*银行账户资料*/
	.bank-info-box{
		.upload-explain{
			background: #FFFBE6;
			width: 100%;
			line-height: 1.8;
			padding: 10px 15px;
			margin-top: 20px;
			border: 1px solid #FFFBE6;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			span{
				display: flex;
				line-height: 1.8;
				color: #666;
				padding-left: 20px;
				margin-top: 10px;
			}
			img{
				vertical-align: -2px;
				margin-right: 5px;
			}
			a{
				color: #C70009;
			}
		}
		.bank-info{
			li{
				margin-top: 20px;
				.upload-demo{
					margin-top: 10px;
					margin-left: 40px;
				}
			}
		}
	}
	/*操作员资料*/
	.operator-info-box{
		.el-form-item{
			margin-top: 20px;
			/deep/ .el-form-item__content{
				display: flex;
				width: 89%;
				.upload-demo{
					margin-right: 20px;
				}
			}
		}
	}
	.btn{
		text-align: center;
	}
	.upload-img{
		width: 100%;
		height: 100%;
	}
	.el-upload__tip{
		text-align: center !important;
	}
	.dialog{
		width: 100%;
		display: flex;
		justify-content: space-between;
		img{
			width: 100%;
			height: auto;
		}
		p{
			line-height: 50px;
			font-size: 16px;
			color: #333;
			text-align: center;
		}
		span{
			display: block;
			font-size: 14px;
			text-align: center;
		}
	}
	.company-dialog{
		li{
			width: 49%;
		}
	}
	.bank-dialog{
		li{
			width: 33%;
		}
	}
	
</style>