<template>
	<div>
		<!--企业信息-->
		<el-descriptions title="企业信息" :column="2" class="mt30">
			<el-descriptions-item label="企业名称">{{companyAccess.companyName}}</el-descriptions-item>
			<el-descriptions-item label="统一社会信息代码">{{companyAccess.socCode}}</el-descriptions-item>
			<el-descriptions-item label="法人姓名">{{companyAccess.legalName}}</el-descriptions-item>
			<el-descriptions-item label="法人手机号码">{{companyAccess.legalTel}}</el-descriptions-item>
			<el-descriptions-item label="法人证件类型">{{companyAccess.legalIdType}}</el-descriptions-item>
			<el-descriptions-item label="法人证件号码">{{companyAccess.legalIdNo}}</el-descriptions-item>
		</el-descriptions>
		<!--基本信息-->
		<el-descriptions title="基本信息" :column="2" class="mt20">
			<el-descriptions-item label="联系电话">{{companyAccess.companyPhone}}</el-descriptions-item>
			<el-descriptions-item label="企业所在省">{{companyAccess.workProvince}}</el-descriptions-item>
			<el-descriptions-item label="企业所在市">{{companyAccess.workCity}}</el-descriptions-item>
			<el-descriptions-item label="注册地址">{{companyAccess.regAddress}}</el-descriptions-item>
			<el-descriptions-item label="通讯地址">{{companyAccess.commAddr}}</el-descriptions-item>
		</el-descriptions>
		<!--企业登记信息-->
		<h3 class="mt20">企业登记信息</h3>
		<el-form :model="companyAccess"
		         :rules="checkFormRules"
		         ref="checkFormRef"
		         inline
		         label-width="100px">
			<el-form-item label="行业分类：" prop="industryCategory">
				<el-select v-model.number="companyAccess.industryCategory" placeholder="请选择">
					<el-option
									v-for="item in industryCategory"
									:key="item.id"
									:label="item.name"
									:value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="是否涉农：" prop="isAgriculture">
				<el-select v-model.number="companyAccess.isAgriculture" placeholder="请选择">
					<el-option label="是" :value="1"></el-option>
					<el-option label="否" :value="0"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="是否绿色：" prop="isGreen">
				<el-select v-model.number="companyAccess.isGreen" placeholder="请选择">
					<el-option label="是" :value="1"></el-option>
					<el-option label="否" :value="0"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="客户性质：" prop="customerType">
				<el-select v-model="companyAccess.customerType" placeholder="请选择">
					<el-option
									v-for="item in customerType"
									:key="item.id"
									:label="item.name"
									:value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="是否科技：" prop="isTechnology">
				<el-select v-model.number="companyAccess.isTechnology" placeholder="请选择">
					<el-option label="是" :value="1"></el-option>
					<el-option label="否" :value="0"></el-option>
				</el-select>
			</el-form-item>
      <el-form-item label="企业规模：" prop="enterpriseScale">
        <el-select v-model="companyAccess.enterpriseScale" placeholder="请选择">
          <el-option label="小于20人" value="1"></el-option>
          <el-option label="20-300人" value="2"></el-option>
          <el-option label="300-1000人" value="3"></el-option>
          <el-option label="大于1000人" value="4"></el-option>
        </el-select>
      </el-form-item>
		</el-form>
		<!--账户信息-->
		<h3 class="mt20">账户信息</h3>
    <WarmPrompt></WarmPrompt>
		<el-table :data="companyAccess.bankAccountList" border class="bankAccountList">
			<el-table-column prop="bankName" label="账户名称" min-width="180px"></el-table-column>
			<el-table-column prop="bankNumber" label="账号" min-width="180px"></el-table-column>
			<el-table-column prop="openBank" label="开户行" min-width="100px"></el-table-column>
			<el-table-column prop="provinceId" label="开户省" min-width="80px"></el-table-column>
			<el-table-column prop="cityId" label="开户市" min-width="80px"></el-table-column>
			<el-table-column prop="clearingNumber" label="联行号" min-width="120px"></el-table-column>
			<el-table-column prop="bankBranch" label="所属支行名称" min-width="200px"></el-table-column>
			<el-table-column prop="cfcaBankCodeName" label="开户行（票交所）" min-width="140px">
				<template v-slot="scope">
					<el-autocomplete v-model="scope.row.cfcaBankCodeName"
													 :debounce="500"
													 placement="bottom-end"	
													 :trigger-on-focus="false"	
					                 :fetch-suggestions="querySearchAsync"
					                 value-key="bankName"
					                 placeholder="请输入内容"
					                 @select="handleSelect(scope.row)"></el-autocomplete>
				</template>
			</el-table-column>
			<el-table-column prop="isCloseAccount" label="是否结算账户" min-width="120px">
				<template v-slot="scope">
					<el-select v-model="scope.row.isCloseAccount"
					           placeholder="请选择"
					           @change="isCloseAccountChange(scope.row)">
						<el-option label="是" :value="1"></el-option>
						<el-option label="否" :value="0"></el-option>
					</el-select>
				</template>
			</el-table-column>
			<el-table-column prop="accountType" label="账户类别" min-width="120px">
				<template v-slot="scope">
					<el-select v-model.number="scope.row.accountType" placeholder="请选择">
						<el-option
										v-for="item in AccountType"
										:key="item.id"
										:label="item.name"
										:value="item.id">
						</el-option>
					</el-select>
				</template>
			</el-table-column>
			<el-table-column prop="accountStartDay" label="账户生效日" min-width="180px">
				<template v-slot="scope">
					<el-date-picker
									v-model="scope.row.accountStartDay"
									type="date"
									format="yyyy-MM-dd"
									value-format="yyyy-MM-dd"
									placeholder="选择日期">
					</el-date-picker>
				</template>
			</el-table-column>
			<el-table-column prop="accountEndDay" label="账户到期日" min-width="180px">
				<template v-slot="scope">
					<el-date-picker
									v-model="scope.row.accountEndDay"
									type="date"
									format="yyyy-MM-dd"
									value-format="yyyy-MM-dd"
									placeholder="选择日期">
					</el-date-picker>
				</template>
			</el-table-column>
			<el-table-column label="操作" fixed="right" min-width="180px">
				<template v-slot="scope">
					<el-button type="delete" size="mini" @click="handleAccountDel(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<h3 class="mt30">操作员信息</h3>
		<el-table :data="companyAccess.frontUserList" border>
			<el-table-column prop="userName" label="操作员姓名" min-width="120px"></el-table-column>
			<el-table-column prop="userPhone" label="操作员手机号码" min-width="120px"></el-table-column>
			<el-table-column prop="certificateType" label="操作员证件类型" width="120px"></el-table-column>
			<el-table-column prop="certificateNumber" label="操作员证件号码" width="120px"></el-table-column>
			<el-table-column prop="userEmail" label="操作员邮箱" min-width="100px"></el-table-column>
			<el-table-column prop="roleNameListStr" label="操作员角色" min-width="100px"></el-table-column>
			<el-table-column prop="postType" label="操作员职位类型" min-width="120px">
				<template v-slot="scope">
					<el-select v-model.number="scope.row.postType" placeholder="请选择">
						<el-option
										v-for="item in postType"
										:key="item.id"
										:label="item.name"
										:value="item.id">
						</el-option>
					</el-select>
				</template>
			</el-table-column>
			<el-table-column prop="postLevel" label="操作员职位级别" min-width="120px">
				<template v-slot="scope">
					<el-select v-model.number="scope.row.postLevel" placeholder="请选择">
						<el-option
										v-for="item in postLevel"
										:key="item.id"
										:label="item.name"
										:value="item.id">
						</el-option>
					</el-select>
				</template>
			</el-table-column>
			<el-table-column label="操作" fixed="right" width="180px">
				<template v-slot="scope">
					<el-button type="delete" size="mini" @click="handleOperatorDel(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!--签署协议-->
		<h3 class="mt30">签署协议</h3>
		<Licence :list="companyAccess.protocolList" #licence>
			<el-checkbox v-model="licenceChecked">同意签署以下协议</el-checkbox>
		</Licence>
		<!--按钮-->
		<el-button type="primary"
		           class="access-one-btn"
		           :disabled="!licenceChecked"
		           @click="saveAccessOne">
			保存并继续
		</el-button>
	</div>
</template>

<script>
  /*协议*/
	import Licence from "./Licence";
  /*温馨提示*/
  import WarmPrompt from '@/views/Ticket/childrenCpn/WarmPrompt/Index'
  export default {
    name: "AccessOne",
	  components: {
      Licence,
      WarmPrompt
	  },
    mounted(){
      // 获取企业准入数据
      this.getCompanyAccess()
      // 获取行业分类数据
      this.getIndustryCategory()
      // 获取客户性质数据
      this.getCustomerType()
      // 获取账户类别数据
      this.getAccountType()
      // 获取操作员职位类型数据
      this.getPostType()
      // 获取操作员职位级别数据
      this.getPostLevel()
      // 获取cfca银行编码和名称
      this.getCfcaBank()
    },
	  data(){
      return {
        flag: true,
        companyAccess:{}, // 企业准入数据
        industryCategory: [], // 数据字典 - 行业分类数据
        customerType: [], // 数据字典 - 客户性质
        AccountType: [], // 数据字典 - 账户类别
        postType: [], // 数据字典 - 职位类型
        postLevel:[], // 数据字典 - 职位级别
	      cfcaBankList: [], //cfca银行
        // 企业登记信息的表单数据验证
        checkFormRules: {
          industryCategory: [
	          { required: true, message: '请选择行业分类', trigger: 'blur' }
          ],
          isAgriculture: [
            { required: true, message: '请选择是否涉农', trigger: 'blur' }
          ],
          isGreen: [
            { required: true, message: '请选择是否绿色', trigger: 'blur' }
          ],
          customerType: [
            { required: true, message: '请选择客户性质', trigger: 'blur' }
          ],
          isTechnology: [
            { required: true, message: '请选择是否科技', trigger: 'blur' }
          ],
          enterpriseScale: [
            { required: true, message: '请选择企业规模', trigger: 'blur' }
          ]
        },
	      licenceChecked: false // 签署协议
	      
      }
	  },
	  methods: {
      // 获取企业准入数据
      async getCompanyAccess(){
        const {data: res} = await this.$auth.get('cpiaoju-ticket/front-company-register/getCompanyInfo');
        if (res.code !== 200) return this.$message.error(res.msg)
        this.companyAccess = res.data
      },
      // 获取行业分类数据
      async getIndustryCategory(){
        const {data: res} = await this.$auth.get('/cpiaoju-ticket/sys-dict/getByType',{params: {type: 1}});
        if (res.code !== 200) return this.$message.error(res.msg)
        this.industryCategory = res.data
      },
      // 获取客户性质数据
      async getCustomerType(){
        const {data: res} = await this.$auth.get('/cpiaoju-ticket/sys-dict/getByType',{params: {type: 2}});
        if (res.code !== 200) return this.$message.error(res.msg)
        this.customerType = res.data
      },
		  // 获取账户类别数据
		  async getAccountType(){
        const {data: res} = await this.$auth.get('/cpiaoju-ticket/sys-dict/getByType',{params: {type: 3}});
        if (res.code !== 200) return this.$message.error(res.msg)
        this.AccountType = res.data
		  },
		  // 获取职位类型数据
		  async getPostType(){
        const {data: res} = await this.$auth.get('/cpiaoju-ticket/sys-dict/getByType',{params: {type: 4}});
        if (res.code !== 200) return this.$message.error(res.msg)
        this.postType = res.data
		  },
		  // 获取职位级别数据
		  async getPostLevel(){
        const {data: res} = await this.$auth.get('/cpiaoju-ticket/sys-dict/getByType',{params: {type: 5}});
        if (res.code !== 200) return this.$message.error(res.msg)
        this.postLevel = res.data
		  },
		  // 获取cfca银行编码和名称
      async getCfcaBank(queryString = ''){
        const {data: res} = await this.$auth.get("/cpiaoju-ticket/cfca-bank-code/getCfcaBank", {params: {cfcaBankName: queryString}})
        if(res.code !== 200) return this.$message.error(res.msg);
        this.cfcaBankList = res.data.records;
      },
		  // cfca模糊搜索
      querySearchAsync(queryString, cb){
        // 获取cfca银行编码和名称
        this.getCfcaBank(queryString)
	      let result = queryString ? this.cfcaBankList.filter(this.createStateFilter(queryString)) : this.cfcaBankList
	      cb(result)
      },
      createStateFilter(queryString) {
        return (state) => {
          return (state.bankName.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
		  // 监听cfca模糊搜索选择
      handleSelect(row){
        let cfcaBankCodeName = row.cfcaBankCodeName
	      let cfcaItem = this.cfcaBankList.filter(item => item.bankName == cfcaBankCodeName)
        row.cfcaBankId = cfcaItem[0].id
      },
		  // 监听是否结算账户
      isCloseAccountChange(value){
        this.flag = true;
        if (value.isCloseAccount == 1){
          this.companyAccess['bankAccountList'].forEach(item => {
            item.isCloseAccount = 0
            if (value.id == item.id){
              item.isCloseAccount = 1
            }
          })
        }
      },
		  // 监听账户信息列表 - 删除
      handleAccountDel(row){
        let newBankAccountList = this.companyAccess['bankAccountList'].filter(item => row.id !== item.id);
        this.companyAccess.bankAccountList = newBankAccountList;
      },
		  // 监听操作员信息列表 - 删除
      handleOperatorDel(row){
        let newFrontUserList = this.companyAccess['frontUserList'].filter(item => row.id !== item.id)
        this.companyAccess.frontUserList = newFrontUserList
      },
      // 第一步保存
      saveAccessOne(){
        this.$refs.checkFormRef.validate(valid => {
          if (!valid) return;
          this.companyAccess['bankAccountList'].forEach(item => {
           try {
             // 判断开户行（票交所）必填
             if(!item.cfcaBankCodeName){
               this.flag = false
               return this.$message.error('开户行（票交所）必填')
             }else {
               this.flag = true
             }
             //isCloseAccount 是否结算账户
             if (item.accountType == 30){ // accountType:账户类别 == 临时存款账户（30）
               if (item.accountStartDay == null || item.accountEndDay == null){// 判断当账户类别=临时存款账户，账户生效日、账户有效期必填
                 this.flag = false
                 return this.$message.error('账户日期不能为空')
               }else{
                 this.flag = true
               }
             }else{
               // 判断是否结算账户=是，账户类别必填；
               if (item.isCloseAccount == 1 && !item.accountType){
                 this.flag = false
                 return this.$message.error('账户类别不能为空');
               }
             }
           }catch (e) {
             console.log(e)
           }
          })
	        this.$nextTick(async () => {
            if (this.flag === true){
              let params = {
		            id: this.companyAccess.companyExtId,// 供票企业id
                socCode: this.companyAccess.socCode, // 0523加的
		            lxCompanyId: this.companyAccess.id,// 乐享企业id
		            bankAccountListStr: JSON.stringify(this.companyAccess.bankAccountList), // 账户信息（可能多条）
		            frontUserListStr: JSON.stringify(this.companyAccess.frontUserList), //操作员信息（可能多条）
		            companyName: this.companyAccess.companyName, // 企业名称
		            industryCategory: this.companyAccess.industryCategory, // 数据字典 - 行业分类数据
		            isAgriculture: this.companyAccess.isAgriculture, // 数据字典 - 是否涉农
		            isGreen: this.companyAccess.isGreen, // 数据字典 - 是否绿色
		            customerType: this.companyAccess.customerType, // 数据字典 - 客户性质
		            isTechnology: this.companyAccess.isTechnology, // 数据字典 - 是否科技
                enterpriseScale: this.companyAccess.enterpriseScale, // 企业规模 -- 新增
                protocolListStr: JSON.stringify(this.companyAccess.protocolList) //签署协议
		          }
		          const {data: res} = await this.$auth.post('/cpiaoju-ticket/front-company-register/addOrUpdCompanyAccess',this.$qs.stringify(params))
		          if(res.code !== 200) return this.$message.error(res.msg)
	            this.$emit('saveAccessOne', 2)
            }
	        })
        })
      },
	  }
  }
</script>

<style lang="less" scoped>
	.el-form{
		margin-top: 20px;
		.el-form-item{
			width: 48% !important;
		}
	}
	.el-descriptions{
		/deep/ .el-descriptions__body .el-descriptions__table .el-descriptions-item__cell{
			line-height: 2.5 !important;
		}
		/deep/ .el-descriptions-item__container{
			margin-left: 20px !important;
		}
		/deep/ .el-descriptions-item__label.has-colon::before{
			content: '*';
			position: relative;
			top: -1px;
			left: -4px;
			color: red;
		}
		/deep/ .el-descriptions-item__content{
			color: #aaa;
		}
	}
	.el-table{
		margin-top: 20px;
	}
	.access-one-btn{
		display: block;
		margin: 30px auto;
	}
	/deep/ .el-checkbox__label{
		font-size: 16px;
		color: rgba(0,0,0,.85);
	}
	
	/*账户信息*/
	.bankAccountList{
		.el-input{
			width: 150px !important;
		}
	}
</style>