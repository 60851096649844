<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item>企业准入管理 </el-breadcrumb-item>
			<el-breadcrumb-item>企业准入</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="status" v-show="!isShowEdit">
			<span>任务状态：{{companyAccess.auditStatusName}}</span>
			<span v-show="isAuditOpinion">审核意见：{{companyAccess.auditOpinion}}</span>
		</div>
		<el-card v-show="!isShowEdit">
			<!--步骤条-->
			<el-steps :active="activeStep"
			          process-status="wait"
			          finish-status="finish"
			          align-center>
				<el-step title="填写企业准入信息"></el-step>
				<el-step title="企业资料补充"></el-step>
				<el-step title="完成"></el-step>
			</el-steps>
			<!--1   填写企业准入信息-->
			<AccessOne v-if="activeStep === 1"
			           @saveAccessOne="saveAccessOne"/>
			<!--2   企业资料补充-->
			<AccessTwo v-else-if="activeStep === 2"
			           @saveAccessTwo="saveAccessTwo"/>
			<Status v-else>
				<template v-slot:statusBtn>
					<el-button type="primary" size="medium" @click="submitAccess">完成</el-button>
				</template>
			</Status>
		</el-card>
		<AccessDetail :detail="companyAccess" v-show="isShowEdit"/>
	</div>
</template>

<script>
	// 申请准入详情页
	import AccessDetail from "./AccessDetail";
	// 第一步企业准入信息
	import AccessOne from "./childCpn/AccessOne";
	// 第二步企业资料补充
	import AccessTwo from "./childCpn/AccessTwo";
	// 第三步准入完成状态
	import Status from "@/views/Ticket/childrenCpn/Status";
  export default {
    name: "Access",
	  components: {
      AccessDetail,
      AccessOne,
      AccessTwo,
      Status
	  },
	  created(){
      this.getCompanyAccess()
	  },
	  data(){
      return {
        activeStep: 1, // 步骤条当前激活步骤
        companyAccess: {}, // 企业准入数据
	      isShowEdit: false, // 判断是否显示编辑页
	      isAuditOpinion: false, // 判断是否显示审核意见
      }
	  },
	  watch: {
      // 判断显示编辑还是详情
      companyAccess: {
        handler(newValue){
          if (newValue.auditStatus === 3 || newValue.auditStatus === 4 || newValue.auditStatus === 5){
            this.isShowEdit = false
          }else {
            this.isShowEdit = true
          }
          if (newValue.auditStatus === 1 || newValue.auditStatus === 3){
            this.isAuditOpinion = false
          }else {
            this.isAuditOpinion = true
          }
        },
        deep: true,
	      immediate: true
      }
	  },
	  methods: {
      // 获取企业准入数据
      async getCompanyAccess(){
        const {data: res} = await this.$auth.get('cpiaoju-ticket/front-company-register/getCompanyInfo');
        if (res.code !== 200) return this.$message.error(res.msg)
        this.companyAccess = res.data
      },
      // 第一步成功
      saveAccessOne(value){
	      this.activeStep = value
		  },
		  // 第二步成功
      saveAccessTwo(value){
        this.activeStep = value
      },
		  // 完成
      submitAccess(){
        // 获取企业准入数据
        this.getCompanyAccess()
        // 去编辑页
        this.companyAccess.auditStatus = 0
	     
      }
	  }
  }
</script>

<style lang="less" scoped>
.status{
	background: #fdf6ec;
	width: 100%;
	height: 50px;
	line-height: 50px;
	color: #E6A23C;
	padding: 0 20px;
	margin-top: 10px;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
}
</style>